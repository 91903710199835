@import '../variables';

.mat-drawer-content {
  display: flex !important;
  flex-direction: column;
}

.mat-menu-item {
  font-family: $font-main;
}

.mat-progress-bar-fill::after {
  background-color: var(--color-teal-800);
}

.mat-progress-bar-buffer {
  background-color: var(--color-plum-300);
}

.mat-progress-spinner circle,
.mat-spinner circle {
  fill: var(--color-teal-800);
}
