// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '~@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$datum-ui-primary: mat.define-palette(mat.$indigo-palette);
$datum-ui-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$datum-ui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$datum-ui-theme: mat.define-light-theme(
  (
    color: (
      primary: $datum-ui-primary,
      accent: $datum-ui-accent,
      warn: $datum-ui-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($datum-ui-theme);

/* Import YG Core Design Styles */
@import '../node_modules/@yougov/fe-style/dist/index.min.css';
@import './ui/fonts';

@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';

/* Includes particularly difficult properties to override witin Material components */
@import './ui/material-override';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: $font-main;
}

:root {
  // These colors are extension to Core Colors: https://gitlab.yougov.net/sst/fe-style/-/blob/master/src/colors.scss
  --color-dark-green: #60b40d; /* --frog-green */
  --color-light-green: #f1f9ea; /* --fern */
  --color-dark-blue: #00aafb; /* --azure */
  --color-light-blue: #e5f6fe; /* --ice-blue */
  --color-dark-yellow: #ffc000; /* --marigold */
  --color-light-yellow: #fff8e5; /* --vanilla */
  --color-dark-red: #e02020; /* --merlot */
  --color-light-red: #ffe9e9; /* --blush */

  --space-xxs: #{$space-xxs};
  --space-xs: #{$space-xs};
  --space-s: #{$space-s};
  --space-m: #{$space-m};
  --space-l: #{$space-l};
  --space-xl: #{$space-xl};
  --space-xxl: #{$space-xxl};
  --space-xxl-2: #{$space-xxl-2};
}

.font-weight-thin {
  font-weight: $font-weight-thin !important;
}
.font-weight-extralight {
  font-weight: $font-weight-extralight !important;
}
.font-weight-light {
  font-weight: $font-weight-light !important;
}
.font-weight-regular {
  font-weight: $font-weight-regular !important;
}
.font-weight-medium {
  font-weight: $font-weight-medium !important;
}
.font-weight-semibold {
  font-weight: $font-weight-semibold !important;
}
.font-weight-bold {
  font-weight: $font-weight-bold !important;
}
.font-weight-extrabold {
  font-weight: $font-weight-extrabold !important;
}
.font-weight-black {
  font-weight: $font-weight-black !important;
}

@tailwind screens;
