$YGMonolineWebfontPath: './fonts';
$YGMonolineWebfontName: 'YGMonoline';

/* Additional YGMonoline weights */
@font-face {
  font-family: $YGMonolineWebfontName;
  src: url('#{$YGMonolineWebfontPath}/YGMonoline 100 Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: $YGMonolineWebfontName;
  src: url('#{$YGMonolineWebfontPath}/YGMonoline 100i Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: $YGMonolineWebfontName;
  src: url('#{$YGMonolineWebfontPath}/YGMonoline 200 ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: $YGMonolineWebfontName;
  src: url('#{$YGMonolineWebfontPath}/YGMonoline 200i ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: $YGMonolineWebfontName;
  src: url('#{$YGMonolineWebfontPath}/YGMonoline 300 Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: $YGMonolineWebfontName;
  src: url('#{$YGMonolineWebfontPath}/YGMonoline 300i Light.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: $YGMonolineWebfontName;
  src: url('#{$YGMonolineWebfontPath}/YGMonoline 400 Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: $YGMonolineWebfontName;
  src: url('#{$YGMonolineWebfontPath}/YGMonoline 400i Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: $YGMonolineWebfontName;
  src: url('#{$YGMonolineWebfontPath}/YGMonoline 600 SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: $YGMonolineWebfontName;
  src: url('#{$YGMonolineWebfontPath}/YGMonoline 600i SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: $YGMonolineWebfontName;
  src: url('#{$YGMonolineWebfontPath}/YGMonoline 800 ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: $YGMonolineWebfontName;
  src: url('#{$YGMonolineWebfontPath}/YGMonoline 800i ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: $YGMonolineWebfontName;
  src: url('#{$YGMonolineWebfontPath}/YGMonoline 900 Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: $YGMonolineWebfontName;
  src: url('#{$YGMonolineWebfontPath}/YGMonoline 900i Black.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
/* end YGMonoline */
